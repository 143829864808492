let commons = require('../utils/commons');

function initQADropdown(qaNode) {
    const qaDropdwonElements = qaNode.getElementsByClassName('qa-element');
    if (qaDropdwonElements.length <= 0) {
        console.error('initQADropdown: no qaDropdwonElements found by class "qa-element"');
        return false;
    }
    let closeAll = function () {
        for(let i = 0; i < qaDropdwonElements.length; i++) {
            let qaDropdwonElement = qaDropdwonElements.item(i);
            commons.removeClassName(qaDropdwonElement, 'open');
            setTimeout(function () {
                commons.removeClassName(qaDropdwonElement, 'animated');
            }, 300);
        }
    }
    let toggle = function(element) {
        if (commons.hasClassName(element, 'open')) {
            commons.removeClassName(element, 'open');
            setTimeout(function () {
                commons.removeClassName(element, 'animated');
            }, 300);
        } else {
            commons.addClassName(element, 'animated');
            commons.addClassName(element, 'open');
        }
    }
    for(let i = 0; i < qaDropdwonElements.length; i++) {
       let qaDropdwonElement = qaDropdwonElements.item(i);
       let linkOpen = qaDropdwonElement.getElementsByClassName('list-opener')[0];
       if (linkOpen === undefined || !linkOpen) {
           console.error('initQADropdown: linkOpen not found by class "list-opener" in ', qaDropdwonElement);
           return false;
       }
       linkOpen.addEventListener('click', function(e){
           e.preventDefault();
           toggle(qaDropdwonElement);
       });
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const qaContainers = document.getElementsByClassName('qa-list');
    for(let i = 0; i < qaContainers.length; i++) {
        let qaContainer = qaContainers.item(i);
        initQADropdown(qaContainer);
    }
})
