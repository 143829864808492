(function( $ ) {

    $.fn.toggableSpace = function() {

        var jqAllAffectedContainers = this;

        return jqAllAffectedContainers.each(function() {
            const jqCOntainer = $(this);
            const toggleLink = jqCOntainer.find('.toggle>a');
            if (toggleLink.length <= 0) {
                console.error('toggableSpace: no toggleLink found by query ".toggle>a" in', this);
                return false;
            }
            const toggleSpaceBlock = jqCOntainer.find('.space');
            const toggleSpaceContent = jqCOntainer.find('.space-holder');
            if (toggleSpaceBlock.length <=0 || toggleSpaceContent.length <= 0) {
                console.error('toggableSpace: toggleSpaceBlock and toggleSpaceContent not found by queries ".space" and ".space-holder" in ', this);
            }
            const contentHeight = toggleSpaceContent.innerHeight();
            toggleSpaceBlock.height(0);
            if (jqCOntainer.hasClass('open')) {
                toggleSpaceBlock.height(contentHeight);
            }

            toggleLink.click(function(e) {
                e.preventDefault();
                if (jqCOntainer.hasClass('open')) {
                    toggleSpaceBlock.height(0);
                    jqCOntainer.removeClass('open')
                } else {
                    toggleSpaceBlock.height(contentHeight);
                    jqCOntainer.addClass('open')
                }
            })
        });

    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.toggable-space').toggableSpace();
});
