import indexStyles from '../sass/common.scss';
let commons = require('./utils/commons');
import qaDropdownJS from './includes/qa_dropdown.js';
import toggableSpaceJS from './includes/toggable_space.js';
import articleDetailIMG from '../img/article-detail.png';
import mapIMG from '../img/map.png';
import wholesale1IMG from '../img/wholesale-1.png';
import wholesale2IMG from '../img/wholesale-2.png';
import wholesale3IMG from '../img/wholesale-3.png';
import wholesaleCatalog1IMG from '../img/wholesale-catalog-1.png';
import wholesaleCatalog2IMG from '../img/wholesale-catalog-2.png';
import wholesaleCatalog3IMG from '../img/wholesale-catalog-3.png';
import wholesaleCatalog4IMG from '../img/wholesale-catalog-4.png';
import wholesaleCatalog5IMG from '../img/wholesale-catalog-5.png';
import wholesaleCatalog6IMG from '../img/wholesale-catalog-6.png';
import wholesaleCatalog7IMG from '../img/wholesale-catalog-7.png';
import wholesaleCatalog8IMG from '../img/wholesale-catalog-8.png';
import wholesaleCatalog9IMG from '../img/wholesale-catalog-9.png';
import wholesaleCatalog10IMG from '../img/wholesale-catalog-10.png';
import wholesaleCatalog11IMG from '../img/wholesale-catalog-11.png';
import wholesaleCatalo2g1IMG from '../img/wholesale-catalog-12.png';
import wholesaleCatal3og1IMG from '../img/wholesale-catalog-13.png';
import wholesaleCata4log1IMG from '../img/wholesale-catalog-14.png';
import wholesaleCat5alog1IMG from '../img/wholesale-catalog-15.png';
import wholesaleCa6talog1IMG from '../img/wholesale-catalog-16.png';
import wholesaleC7atalog1IMG from '../img/wholesale-catalog-17.png';
import wholesale8Catalog1IMG from '../img/wholesale-catalog-18.png';
import wholesal9eCatalog1IMG from '../img/wholesale-catalog-19.png';
import notFoundIMG from '../img/404.png';
